@tailwind base;
@tailwind components;
@tailwind utilities;

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  background-color: rgba(104, 156, 204, 0.2);
}

.dx-popup-content {
  overflow-y: auto;
}